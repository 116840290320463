<template>
  <!-- <div class="box" :style="styleBox">  -->
  <!-- <div :class="labelTop ? 'boxTop' : 'box'" :style="styleBox"> -->

  <div :class="boxClass">
    <div v-if="label"
        :class="labelTop ? 'labelTop' : 'label'"
        :style="styleLabel">
      {{ label }}
    </div>

    <input
      :id="uuid"
      v-bind="$attrs"
      :class="inputClass"
      :style="styleInput"
      v-model="displayValue"
      v-uppercase="upperCase"
      :maxlength="maxlength||''"
      :placeholder="placeholder||''"
      :type="password? 'password' : 'text'"
      @keydown="onKeyDown"
      @keypress="restrictChars($event)"
      @blur="blurEvent"
      @focus="focusEvent"
    />
  </div>


  <!-- </div> -->
</template>


<script>
export default {
  props: {
    ct: { type: [Array, Object], default: null },
    labelSide: { type: Boolean, default: false },
    label: { type: [String, Number], default: "" },
    styleBox: { type: String, default: "" },
    styleInput: { type: String, default: "" },
    styleLabel: { type: String, default: "" },
    value: { type: [String, Number], default: "" },

    password: { type: Boolean, default: false },
    uCase: { type: Boolean, default: false },
    format: { type: [String], default: "texto" },
    maxlength: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    ndecimals: { type: Number, default: 0 },
    negativo: { type: Boolean, default: true },
    simbolo: { type: String, default: "" },

    teclasF: { type:Array, default:()=> []}
  },

  data() {
    return {
      isInputActive: false,
      modifiedValue: "",
    };
  },

  methods: {
    restrictChars: function ($event) {
      console.log('restrictChars', $event);
      //console.log('**restrictChars',$event,$event.target.value,this.value,this.modifiedValue,this.displayValue);
      // if (
      //   $event.charCode === 0 ||
      //   /\d/.test(String.fromCharCode($event.charCode))
      // ) {
      // console.log($event);

      // $(".number").keypress(function (event) {
      //   if (
      //     ($event.which != 46 || $(this).val().indexOf(".") != -1) &&
      //     (event.which < 48 || event.which > 57) &&
      //     event.which != 0 &&
      //     event.which != 8
      //   ) {
      //     event.preventDefault();
      //   }

      //   var text = $(this).val();

      //   if (
      //     text.indexOf(".") != -1 &&
      //     text.substring(text.indexOf(".")).length > 2 &&
      //     event.which != 0 &&
      //     event.which != 8 &&
      //     $(this)[0].selectionStart >= text.length - 2
      //   ) {
      //     event.preventDefault();
      //   }
      // });
      if ($event.keyCode=='13') this.$emit('Enter');

      if (this.numerico) {
        console.log(
          "evento: ",
          $event,
          "key:",
          $event.key,
          "displayValue",
          this.displayValue,
          "value:",
          this.value,
          "modificado:",
          this.modifiedValue
        );

        // (permitir punto decimal) Si pulsa . y no hay ningun . y permite decimales
        if (
          $event.key == "." &&
          this.modifiedValue.indexOf(".") == -1 &&
          this.decimales > 0
        ) return;

        // let decimales=this.modifiedValue.split(".");
        // // si el valor anterior era igual pero con un decimal mas
        // if (decimales.length > 1){
        //   if (decimales[1].length>1) console.log("mas de un decimal")
        // }

        // permito introducir negativos en numéricos si la propiedad asi lo indica
        if ($event.key== '-' && this.modifiedValue.indexOf("-") == -1 && this.negativo) return;

        if ($event.key < "0" || $event.key > "9") {
          $event.preventDefault();
        }

      }
    },

    changeText(event) {
      console.log('*changeText',event,this.value,this.displayValue);

      // pasar a mayusculas si uCase y formato cif
      //if (this.uCase || this.format=='cif') event.target.value=event.target.value.toUpperCase();

    },


    blurEvent(event) {
      console.log('blurEvent', event);
      this.isInputActive = false;
      let ctrl= document.getElementById(event.srcElement.id);

      //pasamos a mayusculas
      if (this.format=='cif'||this.format=='banco'||this.uCase) this.displayValue=this.value.toUpperCase();

      //validamos cif
      if (this.format=='cif' && this.value) {
        // valido cif/dni/nie
        let cif=this.validaCif(this.value);

        if (!cif) {
          this.$root.$alert.open('NIF / CIF Incorrecto ' + this.value, 'error', 1500);
          //ctrl.focus();
          setTimeout(function() { ctrl.focus(); }, .1); // para que funcione chrome ... esperamos
          return;
        } else {
          this.displayValue=cif;
        }

      }

      //validamos banco
      if (this.format=='banco' && this.value) {
        let cta=this.validaBanco(this.value);
        if (!cta) {
          this.$root.$alert.open('Cuenta Bancaria Incorrecta ' + this.value, 'error', 1500);
          setTimeout(function() { ctrl.focus(); }, .1);
          return;
        } else {
          this.displayValue=cta;
        }
      }

      //this.modifiedValue= parsefloat(this.modifiedValue);
      this.$emit("input", this.modifiedValue);
      this.$emit('blur');
    },


    focusEvent() {
      console.log('focusEvent', event);
      this.modifiedValue= this.value;
      this.isInputActive = true;
      this.$emit('focus');
    },

    // devolvemos false si erroneo o el cif limpio
    validaCif(cif) {

      if (cif.charAt(0)=='*') return cif;
      cif = cif.toUpperCase().replace(/[_\W\s]+/g, '');

      // dni/nie
      if(/^(\d|[XYZ])\d{7}[A-Z]$/.test(cif)) {
        let num = cif.match(/\d+/);
        num = (cif[0]!='Z'? cif[0]!='Y'? 0: 1: 2) + num;
        if(cif[8]=='TRWAGMYFPDXBNJZSQVHLCKE'[num%23]) {
            //return /^\d/.test(cif)? 'DNI': 'NIE';
            return cif;
        }
      }
      // sociedad
      else if(/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(cif)) {
        return this.isValidCif(cif) ? cif : false;
      }

      return false;
    },

    // socieddades
    isValidCif(cif) {
      var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
      var digits = cif.substr(1, cif.length - 2);
      var letter = cif.substr(0, 1);
      var control = cif.substr(cif.length - 1);
      var sum = 0;
      var i;
      var digit;

      if (!letter.match(/[A-Z]/)) return false;

      for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);
        if (isNaN(digit)) return false;
        if (i % 2 === 0) {
          digit *= 2;
          if (digit > 9) digit = parseInt(digit / 10) + (digit % 10);
          sum += digit;
        } else {
          sum += digit;
        }
      }
      sum %= 10;
      digit=(sum!==0 ? 10-sum : sum);

      if (letter.match(/[ABEH]/))   return String(digit) === control;
      if (letter.match(/[NPQRSW]/)) return letters[digit] === control;

      return String(digit) === control || letters[digit] === control;
    },

    validaBanco(cta) {
      if (cta.charAt(0)=='*') return cta;
      cta = cta.toUpperCase().replace(/[_\W\s]+/g, '');
      //cta = cta.toUpperCase().replace(/[^A-Z0-9]/g,'');

      let cc=cta.substr(-20);

      //validamos dc
      if (cta.length>=20) {
        let ban=cc.substr(0,4);
        let suc=cc.substr(4,8);
        let dc=cc.substr(8,10);
        let ccc =cc.substr(-10);

        var V=[1,2,4,8,5,10,9,7,3,6];

        //cuenta
        let control = 0;
        for (var i=0; i<=9; i++) control += parseInt(ccc.charAt(i)) * V[i];
        control = 11 - (control % 11);
        control=(control==11?0:(control==10?1:control));
        if (control!=parseInt(dc.charAt(1))) return false;
        //bancoSucursal
        control=0;
        var zbs="00"+ban+suc;
        for (i=0; i<=9; i++) control += parseInt(zbs.charAt(i)) * V[i];
        control = 11 - (control % 11);
        control=(control==11?0:(control==10?1:control));
        if (control!=parseInt(dc.charAt(0))) return false;
      }

      //añadimos IBAN sino lo tiene
      if (cta.length==20) {
        cta='ES' + this.calculaIBAN(cc) + cc;
      }

      //validamos iban
      if (cta.length==24) {
        if (this.calculaIBAN(cta) != cta.substr(2,2)) return false;
      }  else
        return false;

      return cta; //true;
    },

    calculaIBAN(cta) {
      let pais=(cta.length==20?'ES':cta.substr(0,2));
      let cc=cta.substr(-20);
      let dividendo = cc + (Number(pais.charCodeAt(0)-55)) + (Number(pais.charCodeAt(1)-55)) + '00';
      var DC = 98-modulo(dividendo, 97);
      return String(DC).padStart(2,'0');

      function modulo(valor, divisor) {
        var resto=0;
        var dividendo=0;
        for (var i=0;i<valor.length;i+=10) {
          dividendo = resto + "" + valor.substr(i, 10);
          resto = dividendo % divisor;
        }
        return resto;
      }
    },

    onKeyDown(e) {
      console.log('onKeyDown');
      if (!this.teclasF || !this.teclasF.length) return;

      this.teclasF.forEach(tecla => {
        if (e.key!= tecla.key) return;

        e.preventDefault();
        tecla.fnt(e);
      });
    },
  },


  computed: {
    ctUi: function () {
      return this.ct
    },

    labelTop: function(){
       if (this.labelSide) return false;
      return true;
    },

    upperCase() {
      return (this.uCase || this.format=='cif' || this.format=='banco');
    },

    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          /* if (this.numerico){
            return this.value.toString()=='0'?'': this.value.toString();
          }
          return this.value.toString();*/
          return this.modifiedValue.toString();
        }
        let valor = this.value;

        // User is not modifying now. Format display value for user interface

        // Numérico
        // REVISAR PORQIE EL TOFIXED NO RENDONDEA BIEN .555 redondea a .55 en vez de .56
        //if (this.numerico) {
        if (this.numerico) {
          valor = Number(this.value)
            .toFixed(this.decimales)
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        }

        return valor + this.simbol;
      },

      set: function (modifiedValue) {
        console.log('set DisplayValue', modifiedValue);
        this.modifiedValue = (this.numerico ? modifiedValue.replace(/,/g, '.') : modifiedValue);

        let newValue = this.modifiedValue;

        // Numérico
        if (this.numerico) {
          /* if (this.modifiedValue) { */
            // Recalculate value after ignoring "$" and "," in user input
            // eslint-disable-next-line no-useless-escape
            //newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
            //this.modifiedValue = this.modifiedValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            if (this.modifiedValue) this.modifiedValue = this.modifiedValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            let newValue = parseFloat(this.modifiedValue.replace(/[^\d.]/g, ""));

            // Ensure that it is not NaN
            if (isNaN(newValue)) {
              newValue = 0;
            }
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it

        //console.log("newValue", newValue);
        this.$emit('input', newValue);
      },
    },
    decimales() {
      if (this.format === "money")  return 2;
      if (this.format === "entero") return 0;
      if (this.format === "decimal") return this.ndecimals>1?this.ndecimals:1;
      return this.ndecimals;
    },

    simbol() {
      if (this.format === "money") return "€";
      return this.simbolo;
    },
    numerico() { //cod.postal = entero 5
      if (this.format === "texto" || this.format === "cif" || this.format === "banco"
        || (this.format=='entero' && this.maxlength==5))
        return false;
      return true;
    },
    boxClass(){
     let clase=''
    //  <div :class="labelTop ? 'boxTop' : 'box'" :style="styleBox">
     clase=this.labelTop?'boxTop': 'box'
     clase+=this.isInputActive?' boxFocused':'';
     return clase;
    },


    inputClass() {
      let className= "";
      className= this.labelTop? 'inputTop' : 'input';

      // añado el nombre del formato a la clase
      className= className + ' ' + this.format;

      // si la propiedad uCase=true añado el nombre upperCase a la clase
      // la propiedad upperCase convierte el texto en mayúscula
      className= className + (this.uCase? ' upperCase' : '' );

      return className;
    },


    // texto aleatorio para asignar como id del control
    uuid() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

  },
};
</script>



<style scoped>
.label {
  /* por defecto el label ocupa siempre su contenido */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 6px 2px 18px;

  font-size: 2rem;
  border-right: 1px solid white;
  border-radius: 18px 0 0 18px;
  background-color: rgb(176, 190, 197, 0.35);
  color: steelblue;
  user-select: none
}

.labelTop {
  font-size: 0.85rem;
  color: steelblue;
  /* padding: 5px 12px 0 0; */
  padding-top: 5px;
  line-height: 10px;
  user-select: none
}

.inputTop {
  flex: 1 1 100%;
  /* poner por defecto a los numéricos alineación izquierda? */
  /* text-align: right; */
  padding: 0px 12px 0 0;
  /* color: red; */
}
.styleBox {
    outline: 0;
    box-shadow: 0 0 1px blue;
}

.input {
  width:100%;
  /* text-align: right; */
  padding: 0px 12px 0 8px;
}
.box {
  flex: 1 1 auto;
  display: flex;
  height: 2rem;
  font-size: 1rem;
  border-radius: 18px;
  background-color: rgb(226,226, 226);
  margin: 0 0px 4px 0;

}

.boxTop {
  height: 37px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  border-radius: 20px;
  background-color: rgb(226, 226, 226);
  margin: 0 0px 4px 0;
  padding: 0px 0px 0 18px;
}
.boxFocused{
  box-shadow: 0px 0px 3px  steelblue;
}


.box .fieldError {
  /* background-color: lightblue; */
  /* outline:none; */
  /* border-radius: 0 ; */
  /* border: 1px solid steelblue; */
  /* outline: 2px solid red; */
  /* outline-style:inset; */

  color: white;
  background-color: lightcoral;
}

.money {
  text-align: right;
}

input:focus {
  outline: none;
}

/* .upperCase {
  text-transform:uppercase;
} */


</style>
